import React, { useState, useEffect } from 'react';
import { useParams, Navigate, Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import AuthService from './AuthService';
import ScoresList from './ScoresList';
import './Games.css'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function GamesList(props) {
  const [games, setGames] = useState([]);

  useEffect(() => {
    const bearerToken = new AuthService().getToken();
    if(bearerToken != null) {
      fetch(`/games`, {
        method: 'GET',
        headers: {
          Authorization: bearerToken
        }
      })
      .then(resp => resp.json())
      .then(data => { setGames(data) })
    }
  }, [])

  if((new AuthService()).loggedIn()) {
    return <>
    <Container>
    <h3>All Games</h3>
    <Table striped bordered>
      <thead>
        <tr><th colSpan="4"></th><th><Link to='/players'>Players</Link>&nbsp;&nbsp;<Link to='/logout'>logout</Link></th></tr>
        <tr><th>Id</th><th>Word</th><th>Player</th><th>Guesses</th><th>Created_at</th></tr>
      </thead>
      <tbody>
      { games.length > 0 ? games.reverse().map((game) => {
          return (
            <tr key={game.id}>
              <td>{game.id}</td>
              <td>
                <Link to={`/games/${game.id}`}>
                  {game.word}
                </Link>
              </td>
              <td>
                {game.playerid}
              </td>
              <td>
                {game.guesses}
              </td>
              <td>
                {game.created_at}
              </td>
            </tr>
          )
        }) : "No games yet today"
      }
      </tbody>
    </Table>
    <ScoresList/>
    </Container>
  </>
  } else {
    return <Navigate to='/login' />
  }
}
